.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 10px;
    /* margin-bottom: 2em; */
    background: #fefefe;
    font-family: 'Oxygen';
  }

    
  .editor :global(.public-DraftEditorPlaceholder-inner){
    font-family: 'Oxygen';
    color: #686868;
    font-weight: 400;
    opacity: 0.5;
  }

  .editor :global(.public-DraftEditor-content) {
    min-height: 140px;
  }

  .editor :global(.mnw6qvm){
    max-height: 150px;
    overflow-y: scroll;
  }