.wysiwyg-hashtag {
    color: rgb(29, 155, 240);
    font-family: 'Oxygen';
    text-decoration: none;
}

.post-viewer-mention {
    color: rgb(29, 155, 240);
    font-family: 'Oxygen';
    text-decoration: none;
}

.post-viewer-mention::before {
    content: '@';
}

.news-block {
    font-family: 'Oxygen';
}

.news-block a {
    text-decoration: none;
}