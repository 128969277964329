html {
    scroll-behavior: smooth;
    scroll-padding-top: 150px;
}

.informationOrange {
    color: #d67e44;
}

.slider .informationBlockOrange {
    background-color: rgb(224, 213, 186);
    color: #d67e44;
}

html,
body {
    margin: 0px;
    padding: 0px;
}

.profile .informationBlockGray {
    background-color: #F9F9F9
}

.page .informationBlockGray {
    background-color: #F9F9F9
}

.page .informationBlockWhite {
    background-color: #FFFFFF;
    border: 'none';
    box-shadow: 'none';
}

.listed-data-bold-cell {
    color: #0D0D0D;
    font-weight: 700;
}

.listed-data-green-cell {
    color: #0F9764;
    font-weight: 700;
}

.listed-data-red-cell {
    color: #FB645C;
    font-weight: 700;
}

.listed-data-grey-cell {
    color: #686868;
}

.listed-data-cyan-cell {
    color: #12626C;
    font-weight: 700;
}

@font-face {
    font-family: "Oxygen";
    src: local("Oxygen"),
        url("./Oxygen/Oxygen-Regular.ttf") format("truetype"),
        url("./Oxygen/OxygenMono-Regular.ttf") format("truetype"),
        url("./Oxygen/Oxygen-Bold-unhinted.ttf") format("truetype"),
        url("./Oxygen/Oxygen-Light-unhinted.ttf") format("truetype");
}

.link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

@-webkit-keyframes chatbouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0)
    }
    40% {
      -webkit-transform: scale(1.0)
    }
  }
  
  @keyframes chatbouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }